import { useUser } from '../user'
import { useTransactionsModal, useTransfersModal } from '~/src/composables/core/modals'
import { useAlert } from '~/src/composables/core/useNotification'
import { transferApi } from '~/src/services/requests/transactions/index'
import { usePagination } from '~/src/composables/core/utils'
import { TransactionDetails } from '~/src/services/requests/transactions/type'
const { user } = useUser()

const transactionInfo = ref<TransactionDetails>()
const transferInfo = ref('') as any
const transferData = ref([] as any)
let createFunc: () => void
let credentials: Record<string, any>
let confirmTransferLoading: boolean

export const ShowTransactionDetails = () => {
  const loading = ref(false)
  const receiptLoading = ref(false)
  const openModal = (data: TransactionDetails) => {
    transactionInfo.value = data
    if (!transactionInfo.value)
      return useAlert().openAlert({
        type: 'ERROR',
        msg: 'Transaction Info can not be null',
      })
    else useTransactionsModal().openTransactionDetails()
  }

  const getTransactionDetails = async (id: string) => {
    loading.value = true
    const res = await transferApi.$_getTransactionDetails(id)
    loading.value = false
    if (res !== 'ERROR') {
      transactionInfo.value = res?.data?.data
    }
  }
  const downloadReceipt = async (id: string, sendThroughMail = false) => {
    receiptLoading.value = true
    const response = ref()
    if (sendThroughMail) {
      response.value = (await transferApi.$_generateReceipt({ transaction_id: id, email: user.value?.email })) as any
    } else {
      response.value = (await transferApi.$_generateReceipt({ transaction_id: id })) as any
    }
    receiptLoading.value = false
    if (response.value !== 'ERROR') {
      useAlert().openAlert({ type: 'SUCCESS', msg: response.value.data.message })
    }
  }

  return { openModal, transactionInfo, getTransactionDetails, loading, receiptLoading, downloadReceipt }
}

export const ShowTransferDetails = () => {
  const openModal = (data: Record<string, unknown>) => {
    transferInfo.value = data
    if (!transferInfo.value)
      return useAlert().openAlert({
        type: 'ERROR',
        msg: 'Transaction Info can not be null',
      })
    else useTransfersModal().openTransferDetails()
  }

  return { openModal, transferInfo }
}

export const getAllTransfers = () => {
  const { metaObject, showLoadMore, loadMore, setFunction } = usePagination()

  const loading = ref(false)
  const fetchTransfer = async (updating = false) => {
    loading.value = true
    const res = (await transferApi.$_getAllTransfers(metaObject.page.value)) as any
    loading.value = false
    if (res !== 'ERROR') {
      if (updating) {
        transferData.value = [...transferData.value, ...res?.data?.data]
      } else {
        transferData.value = res?.data?.data
      }
    }
    metaObject.total.value = res?.data.meta.total
  }
  setFunction(fetchTransfer)
  return { fetchTransfer, loading, transferData, showLoadMore, loadMore }
}

export const confirmTransfer = () => {
  const setFunction = (func: () => void, cred: any) => {
    if (cred === undefined) throw new Error('you need to pass the credentials')
    createFunc = func
    credentials = cred
    useTransfersModal().openConfimationModal()
  }

  return { setFunction, createFunc, credentials, confirmTransferLoading }
}
