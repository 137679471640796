import { useAlert } from '../core/useNotification'
import { useEnvironment } from '../utils/useEnviroment'
import { useBusiness } from './business'
import { useUser } from './user'
import { ForgotPassword, Login, ResetPassword, SignUp, VerifyEmail } from '~/src/services/requests/auth/type'
import { MAX_OTP_ATTEMPTS, OTP_STAGING_VALUE } from '~/src/constants'
import { ROUTES_NAMES } from '~/src/routes'
import { authApi } from '~/src/services/requests/auth'

export const useRegister = () => {
  const step = ref(0)
  const loading = ref(false)

  const register = async (data: SignUp) => {
    loading.value = true
    const res = await authApi.$_register(data)

    if (res !== 'ERROR') {
      step.value++
    }

    loading.value = false
  }

  return { credential, register, loading, step }
}

const credential = reactive({
  otp: '',
  verificationToken: null as null | string,
  attempts: 0,
})

export const useLogin = () => {
  const router = useRouter()
  const { isProduction } = useEnvironment()

  const loading = ref(false)

  const login = async (data: Login) => {
    loading.value = true
    const res = (await authApi.$_login(data)) as any

    if (res !== 'ERROR') {
      credential.verificationToken = res?.data?.data?.verification_token

      if (isProduction()) {
        router.push('/auth/otp')

        return
      }

      // on staging and sandbox verify otp directly
      await verifyLogin(OTP_STAGING_VALUE)
    }

    loading.value = false
  }
  const verifyLogin = async (otp: string) => {
    loading.value = true

    credential.attempts = credential.attempts + 1
    const res = await authApi.$_verify_otp({
      verificationToken: credential.verificationToken as string,
      otp,
    })

    if (res !== 'ERROR') {
      await useUser().setUser(res?.data?.data)
      await useBusiness().fetchAllBusinesses()
      router.push({
        name: ROUTES_NAMES.pickAccount,
      })
    } else if (credential.attempts > MAX_OTP_ATTEMPTS) {
      credential.verificationToken = null
      credential.attempts = 0
      router.push('/auth/login')
    }

    loading.value = false
  }

  return { credential, login, loading, verifyLogin }
}

export const useInvitation = () => {
  const router = useRouter()
  const route = useRoute()
  const { logOut } = useUser()
  const state = reactive({
    loading: false,
    confirming: false,
    businessName: '',
  })

  const gotoLogin = () => {
    logOut(`/auth/login?redirect=${route.path}`)
  }

  const confirmInvite = async (reference: string) => {
    state.confirming = true
    const res = await authApi.$_confirmInvite(reference)

    if (!res?.status) {
      gotoLogin()
    }

    sessionStorage.setItem('accessToken', res?.data?.token)
    state.businessName = res.business_name
    state.confirming = false
  }

  const acceptInvite = async () => {
    state.loading = true

    const inviteResponse = await authApi.$_acceptInvite()

    if (!inviteResponse?.status) {
      logOut()
    }

    sessionStorage.setItem('accessToken', inviteResponse?.data?.token)
    await useBusiness().fetchAllBusinesses()
    router.push({
      name: ROUTES_NAMES.pickAccount,
    })

    state.loading = false
  }

  return { acceptInvite, confirmInvite, state }
}

export const forgotPassword = () => {
  const loading = ref(false)

  const sendLink = async (data: ForgotPassword) => {
    loading.value = true
    const res = await authApi.$_forgotPassword(data)

    if (res !== 'ERROR') {
      useAlert().openAlert({
        type: 'SUCCESS',
        msg: res?.data?.message,
      })
    }

    loading.value = false
  }

  return { credential, loading, sendLink }
}

export const resetPassword = () => {
  const loading = ref(false)

  const resetPassword = async (data: ResetPassword, options?: { onSuccess?: () => void; onError?: () => void }) => {
    loading.value = true
    const res = await authApi.$_resetPassword(data)

    if (res !== 'ERROR') {
      useAlert().openAlert({
        type: 'SUCCESS',
        msg: res?.data?.message,
      })
      options?.onSuccess?.()
    }

    loading.value = false
  }

  return { credential, loading, resetPassword }
}

export const useVerifyEmail = () => {
  const loading = ref(false)
  const error = ref(false)

  const verify = async (credential: VerifyEmail) => {
    loading.value = true
    const res = await authApi.$_VerifyEmail(credential)
    if (res !== 'ERROR') {
      useAlert().openAlert({
        type: 'SUCCESS',
        msg: res?.data?.message,
      })
    } else {
      error.value = true
    }
    loading.value = false
  }

  return { loading, error, verify }
}

export const useResendVerification = () => {
  const loading = ref(false)

  const resendVerification = async (email: string) => {
    loading.value = true
    const res = await authApi.$_ResendVerification({
      email,
    })

    if (res !== 'ERROR') {
      useAlert().openAlert({
        type: 'SUCCESS',
        msg: res?.data?.message,
      })
    }

    loading.value = false
  }

  return { loading, resendVerification }
}
