<template>
  <NuxtLoadingIndicator />

  <NewDashboard>
    <div class="w-full">
      <PageHeader title="Settings" />
      <nav>
        <ul class="flex gap-3 flex-wrap py-4">
          <li
            v-for="link of settingsRoute"
            :key="link.route"
          >
            <NavigationTagLink :to="link.route">
              {{ $t(link.name) }}
            </NavigationTagLink>
          </li>
        </ul>
      </nav>
      <LayoutContent :spacing="settingsHeader">
        <section class="flex flex-col h-full">
          <slot />
        </section>
      </LayoutContent>
    </div>
  </NewDashboard>
</template>

<script lang="ts" setup>
// import Alert from '~/src/components/core/notification/Alert.vue'
import NewDashboard from './newDashboard.vue'
import { useActivityChecker } from '~/src/composables/utils/useActivityChecker'
import { settingsHeader, totalHeaderHeight } from '~/src/constants'

const { startCounter } = useActivityChecker()
onMounted(() => startCounter())

const headerHeight = `${totalHeaderHeight}px`

const settingsRoute = [
  {
    name: 'settings.profile',
    route: '/settings/profile',
  },
  {
    name: 'settings.apiKeysAndWebhooks',
    route: '/settings/webhooks',
  },
  {
    name: 'settings.roles',
    route: '/settings/roles',
  },
  {
    name: 'settings.team',
    route: '/settings/teams',
  },
  {
    name: 'settings.ipWhitelist',
    route: '/settings/ip-whitelist',
  },
  {
    name: 'settings.auditLog',
    route: '/settings/audit-log',
  },
]

useHead({
  title: 'Maplerad Dashboard',
  script: [{ src: 'script/intercom.js' }],
  link: [{ rel: 'icon', type: 'image', href: '/favicon.png' }],
})
</script>

<style scoped>
.main {
  padding-top: v-bind(headerHeight);
  @apply h-screen bg-white px-5;
}

.main-content {
  @apply flex gap-8 overflow-hidden;
}
</style>
