import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { mixed, object, string, boolean, array, number } from 'yup'
import { BusinessRegisterationType, TestEnvironment } from '~/src/types/api'

export const businessKycSchema = object({
  typeOfRegistration: mixed<BusinessRegisterationType>()
    .oneOf(Object.values(BusinessRegisterationType))
    .required()
    .label('registration type'),
  registrationNumber: string().required().label('registration number'),
  phoneNumber: string()
    .required()
    .test('is-valid-phone-number', 'Phone number does not match country selected', function (value) {
      try {
        const country = parsePhoneNumber(value).country

        return isValidPhoneNumber(value, country)
      } catch (error) {
        return false
      }
    })
    .label('phone number'),
  taxIdNumber: string().required().label('tax identification'),
  website: string(), // TODO: validate url
  description: string().required().label('business Description'),
  industry: string().required().label('industry'),
  dateOfIncorporation: string().required(),
  name: string().required(),
}).required()

export const businessAddressSchema = object({
  address: object().shape({
    country: string().required(),
    street: string().required(),
    apartment: string(),
    city: string().required(),
    postalCode: string().required(),
    state: string().required(),
  }),
}).required()

export const selectBusinessRequestSchema = object({
  businessId: string().required(),
}).required()

export const toggleTestAccountSchema = object({
  environment: mixed<TestEnvironment>().oneOf(Object.values(TestEnvironment)).required().label('registration type'),
}).required()

export const businessSchema = object({
  id: string().required(),
  accountNumber: string().required(),
  name: string().required(),
  email: string().email().required(),
  phoneNumber: string().nullable(),
  supportEmail: string().nullable(),
  supportPhoneNumber: string().nullable(),
  description: string().nullable(),
  address: string().nullable(),
  state: string().nullable(),
  street: string().nullable(),
  city: string().nullable(),
  apartment: string().nullable(),
  postalCode: string().nullable(),
  country: string().required(),
  logo: string().nullable(),
  live: boolean().required(),
  verificationLevel: number().required(),
  stage: number().required(),
  approvalStatus: string().required(),
  approverMessage: string().nullable(),
  website: string().default(''),
  monthlyPaymentVolume: string().default(''),
  numberOfEmployees: string().default(''),
  interestedSolutions: array().of(string()).required(),
  isRegistered: boolean().required(),
  createdAt: string().required(),
  updatedAt: string().required(),
  featureFlags: array().of(string()).required(),
  isOwner: boolean().required(),
})
