<template>
  <Modal
    modal="$atts.modal"
    :title="$t('settings.addWebhook')"
  >
    <p class="text-greyDark text-sm text-center mt-3 max-w-[400px]">{{ $t('settings.enterWebhookUrl') }}</p>
    <input
      id="Bname"
      v-model="credential.webhook_url.value"
      type="text"
      name="Bname"
      class="input mt-3.5 w-full"
    />

    <button
      class="btn-primary mt-7"
      :disabled="loading"
      @click="create"
    >
      <span
        v-if="!loading"
        class="mr-4"
      >
        {{ $t('settings.createWebhook') }}
      </span>
      <Spinner v-else />
    </button>
  </Modal>
</template>

<script lang="ts" setup>
import { createWebhook } from '~/src/composables/apis/business/webhook'

const { credential, create, loading } = createWebhook()
</script>

<style scoped></style>
