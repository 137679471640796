import { Shareholder } from '../services/requests/kyc/types'
import { User } from '../services/requests/roles/type'
import { CurrencyCode } from './internals'

export interface TransferActivity {
  account_number: string
  amount: number
  bank: string
  currency: CurrencyCode
  transaction_id?: string
}
export interface CardActivity {
  card_id?: string
  customer_id?: string
  reference?: string
  amount?: number
}

export interface InviteActivity {
  email: string
  role: string
}

export interface IpWhitelistingActivity {
  whitelisted_ip: string
}

export enum ActivityType {
  FailedTransfer = 'failed-transfer',
  ProcessedTransfer = 'processed-transfer',
  FailedCardCreation = 'failed-card-creation',
  ProcessedCardCreation = 'processed-card-creation',
  ViewCardDetails = 'view-card-details',
  FundCard = 'fund-card',
  CardWithdrawal = 'card-withdrawal',
  InviteUser = 'invite-user',
  IpWhitelisting = 'ip-whitelisting',
}

export type Activities<T> = T extends ActivityType.IpWhitelisting
  ? IpWhitelistingActivity
  : T extends ActivityType.InviteUser
    ? InviteActivity
    : T extends ActivityType.FailedTransfer
      ? TransferActivity
      : T extends ActivityType.ProcessedTransfer
        ? TransferActivity
        : CardActivity

export type Activity<T> = {
  ip_address: string
  time_stamp: string
  type: ActivityType
} & Activities<T>

export type ActivityLog<T> = {
  id: string
  user_id: string
  action: string
  created_at: string
  activity: Activity<T> | null
}

export type Pagination = {
  page: number
  size: number
  has_next_page: boolean
  has_previous_page: boolean
  total_count: number
}

export type ServerResponse<T> = {
  status: boolean
  message: string
  data: T
  meta?: Pagination
}

export type AccountStatusResponse = {
  currency: CurrencyCode
  reference: string
  status: boolean | string
}

export enum ApprovalStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  New = 'NEW',
}
export enum DocumentType {
  statusReport = 'status_report',
  memorandum = 'memorandum_and_articles_of_association',
}

export enum VerificationLevel {
  Default = 0,
  LevelOne = 1,
  LevelTwo = 2,
}

export enum PermissionType {
  Transaction = 'transaction',
  Transfer = 'transfer',
  Wallet = 'wallet',
  Fxconversion = 'fx-conversion',
  Cards = 'cards',
  Customer = 'customer',
  Setting = 'setting',
  Webhooks = 'webhooks',
}

export type Log = {
  id: string
  user_id: string
  action: string
  created_at: string
  activity: null
  user: Pick<User, 'firstName' | 'lastName'>
}

export type PostRole = {
  permissions: string[]
  name: string
  description: string
}

export type Invite = {
  email: string
  role: string
}

export type Lang = {
  preferredLanguage: string
}

export enum BusinessRegisterationType {
  Limited = 'Limited',
  SoleProprietor = 'SoleProprietor',
  Trustee = 'Trustee',
}

export enum TestEnvironment {
  SANDBOX = 'SANDBOX',
  PRODUCTION = 'PRODUCTION',
}

export type OtherDocs = { file: string; description: string }

export type DocFile = { id?: string; description: string; file: string }

export type Document = {
  identifier: DocumentType
  name: string
  description: string
  required: boolean
  multiple: boolean
  data: DocFile | DocFile[] | null
}

export type Ownership = {
  shareCapital: number | undefined
  shareholder: Shareholder[]
}
