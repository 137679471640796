<template>
  <aside class="border-r border-r-gray10">
    <Dropdown
      class="w-full"
      size="large"
      placement="bottom-start"
      trigger="click"
      @command="handleCommand"
    >
      <button
        class="min-h-[64px] w-full py-4 pr-4 pl-8 flex items-center gap-2 border-b border-b-gray10 justify-between"
      >
        <div class="flex items-center gap-2 flex-1">
          <div class="h-8 w-8 rounded-lg bg-greyBg flex items-center justify-center">
            <MaIcon
              name="institute"
              class="w-5 h-5 text-gray900"
            />
          </div>
          <Typography>{{ business?.name }}</Typography>
        </div>

        <MaIcon
          name="unfold"
          class="h-3 text-gray500 pr-2"
        />
      </button>
      <template #dropdown>
        <DropdownItem command="settings">
          <span class="py-1">
            {{ $t('overview.settings') }}
          </span>
        </DropdownItem>
        <DropdownItem command="referrals">
          <span class="py-1"> {{ $t('Referrals') }}</span>
        </DropdownItem>
        <DropdownItem
          divided
          command="view-all"
        >
          <span class="py-1 flex items-center gap-2">
            <MaIcon
              name="menu-square"
              class="w-[22px] ml-1 text-greyDark mr-2"
            />

            {{ $t('accountCreation.View All Businesses') }}
          </span>
        </DropdownItem>
        <DropdownItem
          v-for="busi in businesses"
          :key="busi.id"
          :command="`switch-${busi.id}`"
        >
          <span class="py-1 flex justify-between items-center w-full">
            <div class="flex items-center gap-2 flex-1">
              <div class="h-8 w-8 rounded-lg bg-greyBg flex items-center justify-center">
                <MaIcon
                  name="institute"
                  class="w-5 h-5 text-gray900"
                />
              </div>
              {{ busi.name }}
            </div>

            <IconsCheckOutlined
              v-if="busi.id === business.id"
              class="text-primary600 h-4 w-4 ml-6"
            />
          </span>
        </DropdownItem>
        <DropdownItem
          v-if="businesses.length < 5 && hasAnApprovedBusiness"
          divided
          command="new-business"
        >
          <span class="py-1"> {{ $t('Apply for a new business') }}</span>
        </DropdownItem>
        <DropdownItem
          divided
          command="sign-out"
        >
          <Typography
            color="text-red"
            class="py-1 min-w-[240px]"
            >{{ $t('overview.logOut') }}
          </Typography>
        </DropdownItem>
      </template>
    </Dropdown>
    <nav>
      <slot />
    </nav>
  </aside>
</template>

<script lang="ts" setup>
import { MaIcon } from '@wirepay/maplerad-component-lib'
import { useBusiness } from '~/src/composables/apis/business'
import { Business } from '~/src/composables/apis/business/types'
import { useUser } from '~/src/composables/apis/user'
import { ComplianceRoutes } from '~/src/types/internals'

const { business, businesses, hasAnApprovedBusiness, updateSelectedBusiness } = useBusiness()
const router = useRouter()
const handleCommand = (command: string) => {
  if (command.startsWith('switch')) {
    const id = command.split('switch-')[1]
    updateBusiness(id)
  } else if (command === 'settings') {
    router.push('/settings/profile')
  } else if (command === 'referrals') {
    //
  } else if (command === 'view-all') {
    router.push('/auth/pick-account')
  } else if (command === 'new-business') {
    router.push('/auth/business')
  } else if (command === 'sign-out') {
    useUser().logOut()
  }
}

const updateBusiness = async (id: string) => {
  const data = businesses.value.find((busi: Business) => busi.id === id)

  if (!data) return
  if (data.id === business?.value?.id) return

  await updateSelectedBusiness(data)

  if (!business.value.live) {
    router.push({
      name: ComplianceRoutes.index,
    })
  } else {
    location.reload()
  }
}
</script>

<style scoped lang="scss"></style>
