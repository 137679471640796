import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import isTokenExpired from '../helpers/isTokenExpired'
import { useAlert } from '~/src/composables/core/useNotification'
import { useUser } from '~/src/composables/apis/user'
// import { global, useBusiness } from '~/src/composables/apis/business'
const { token, logOut } = useUser()

const refreshAccessToken = async () => {
  try {
    const refreshToken = sessionStorage.getItem('refreshToken')
    const response = await REFRESH_TOKEN_ENDPOINT.post(
      '/auth/refresh-token',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    )
    const { access_token: accessToken, refresh_token: newRefreshToken } = response.data.data
    sessionStorage.setItem('accessToken', accessToken)
    sessionStorage.setItem('refreshToken', newRefreshToken)
    return accessToken
  } catch (error) {
    logOut()
    return null
  }
}

// TODO refactor this to a class
const COUNTRY_API_KEY = import.meta.env.VITE_X_CSCAPI_KEY
const $GATEWAY_ENDPOINT = import.meta.env.VITE_GATEWAY_ENDPOINT + '/v1'
const $GATEWAY_ENDPOINT_V2 = import.meta.env.VITE_GATEWAY_ENDPOINT + '/v2'
const $COUNTRY = import.meta.env.VITE_COUNTRY_ENDPOINT

export const COUNTRY_ENDPOINT = axios.create({
  baseURL: $COUNTRY,
})

export const GATEWAY_ENDPOINT_V2 = axios.create({
  baseURL: $GATEWAY_ENDPOINT_V2,
})

export const UN_AUTH_GATEWAY_ENDPOINT = axios.create({
  baseURL: $GATEWAY_ENDPOINT,
})

export const AUTH_GATEWAY_ENDPOINT = axios.create({
  baseURL: $GATEWAY_ENDPOINT,
})
export const GATEWAY_ENDPOINT = axios.create({
  baseURL: $GATEWAY_ENDPOINT,
})
export const REFRESH_TOKEN_ENDPOINT = axios.create({
  baseURL: $GATEWAY_ENDPOINT,
})

const instances = [GATEWAY_ENDPOINT_V2, GATEWAY_ENDPOINT]

const instanceErrorHandlingArray = [
  UN_AUTH_GATEWAY_ENDPOINT,
  AUTH_GATEWAY_ENDPOINT,
  GATEWAY_ENDPOINT,
  GATEWAY_ENDPOINT_V2,
]

COUNTRY_ENDPOINT.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    useAlert().openAlert({ type: 'ERROR', msg: err.response.data.error })
    return 'ERROR'
  },
)

COUNTRY_ENDPOINT.interceptors.request.use((request) => {
  request.headers['x-cscapi-key'] = COUNTRY_API_KEY
  return request
})

UN_AUTH_GATEWAY_ENDPOINT.interceptors.request.use((request) => {
  return request
})

AUTH_GATEWAY_ENDPOINT.interceptors.request.use((request) => {
  if (request?.url?.includes('auth')) {
    return request
  } else {
    request.headers.Authorization = `Bearer ${sessionStorage.getItem('accessToken') || token.value}`
    return request
  }
})

instances.forEach((instance) => {
  instance.interceptors.request.use((request) => {
    // const isLive = useBusiness().business.value.live === true
    // if (request?.url?.includes('payment')) {
    //   request.headers.Authorization = `Bearer ${global.business.value.public_key}`
    //   return request
    // } else if (
    //   request?.url?.includes('auth') ||
    //   request?.url?.includes('businesses') ||
    //   request?.url?.includes('shareholder') ||
    //   isLive
    // ) {
    request.headers.Authorization = `Bearer ${sessionStorage.getItem('accessToken')}`
    return request
    // } else {
    //   return request
    // }
  })
})

instanceErrorHandlingArray.forEach((instance) => {
  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async (err) => {
      if (typeof err.response === 'undefined') {
        useAlert().openAlert({ type: 'ERROR', msg: 'kindly check your network connection' })
        return 'ERROR'
      }
      if (err.response.status === 401) {
        const token = sessionStorage.getItem('accessToken')

        const { exp } = token ? jwtDecode(token) : { exp: null }
        const isExpired = exp && isTokenExpired(exp)

        if (isExpired) {
          // Get the original request config
          const originalRequest = err.config

          // Try to refresh the token
          const newToken = await refreshAccessToken()

          if (newToken) {
            // Update the failed request with new token and retry
            originalRequest.headers.Authorization = `Bearer ${newToken}`
            return instance(originalRequest)
          } else {
            logOut()
            return
          }
        }

        useAlert().openAlert({ type: 'ERROR', msg: err.response.data.message })
        return 'ERROR'
      } else if (
        err.response.status === 400 ||
        err.response.status === 403 ||
        err.response.status === 405 ||
        err.response.status === 500
      ) {
        useAlert().openAlert({ type: 'ERROR', msg: err.response.data.message })
        return 'ERROR'
      }
    },
  )
})
