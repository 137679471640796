import { useEnvironment } from '~/src/composables/utils/useEnviroment'

export enum FeatureToggles {
  Checkout = 'checkout',
  USDAccounts = 'usdAccount',
  NewDashboard = 'newDashboard',
}

enum EnabledEnvironment {
  Development = 'development',
  Sandbox = 'sandbox',
  Production = 'production',
}

const featureToggles = {
  [FeatureToggles.Checkout]: EnabledEnvironment.Sandbox,
  [FeatureToggles.NewDashboard]: EnabledEnvironment.Development,
  [FeatureToggles.USDAccounts]: EnabledEnvironment.Production, // Todo: remove after next release
}

export const useFeatureToggle = (key: FeatureToggles) => {
  const { environmentType } = useEnvironment()

  const enabledEnv = featureToggles[key]
  const isSandbox = environmentType() === EnabledEnvironment.Sandbox
  const isDev = environmentType() === EnabledEnvironment.Development

  // enable everywhere
  if (enabledEnv === EnabledEnvironment.Production) return true

  // enable on development only
  if (enabledEnv === EnabledEnvironment.Development && isDev) return true

  // enable on development and sandbox
  if (enabledEnv === EnabledEnvironment.Sandbox && (isSandbox || isDev)) return true

  return false
}
