<template>
  <NuxtLoadingIndicator />

  <main class="main">
    <SidebarsAside>
      <Navigation :routes="routes" />
    </SidebarsAside>

    <section class="px-8">
      <HeaderDashboard />
      <slot />
    </section>
  </main>
  <Alert />
  <ModalBase />
</template>

<script lang="ts" setup>
import { useActivityChecker } from '~/src/composables/utils/useActivityChecker'
import Alert from '~/src/components/core/notification/Alert.vue'
import { useBusiness } from '~/src/composables/apis/business'
import { useEnvironment } from '~/src/composables/utils/useEnviroment'
import { ApiFeatureFlag } from '~/src/types/internals'

const { startCounter } = useActivityChecker()
onMounted(() => startCounter())

useHead({
  title: 'Maplerad Dashboard',
  script: [{ src: 'script/intercom.js' }],
  link: [{ rel: 'icon', type: 'image', href: '/favicon.png' }],
})

const { business } = useBusiness()
const stashIsEnabled = business.value?.feature_flags?.includes(ApiFeatureFlag.STASH)

const allRoutes = [
  {
    icon: 'rocket-outline',
    name: 'nav.links.getStarted',
    route: '/',
  },
  // {
  //   icon: 'home',
  //   name: 'kyc.home',
  //   route: '/',
  // },
  {
    icon: 'list',
    name: 'nav.links.transactions',
    route: '/transactions',
  },
  {
    icon: 'stash',
    name: 'nav.links.stash',
    route: '/stash',
  },
  {
    icon: 'bank-transfer',
    name: 'payment',
    children: [
      // {
      //   name: 'recipients',
      //   route: '/recipients',
      // },
      {
        name: 'nav.links.billPayment',
        route: '/bills',
      },
    ],
  },
  {
    icon: 'card',
    name: 'nav.links.cards',
    route: '/cards',
  },
  {
    icon: 'convert-currency',
    name: 'nav.links.fxConversion',
    route: '/fx',
  },
  {
    icon: 'users',
    name: 'nav.links.customers',
    route: '/customers',
  },
  {
    icon: 'wallet-coin',
    name: 'nav.links.wallets',
    route: '/wallets',
  },
]

const { isProduction } = useEnvironment()

const routes = computed(() => {
  const staticRoutes = allRoutes.filter((route) => {
    if (!isProduction()) {
      return true
    }
    if (route.name === 'nav.links.stash') {
      return stashIsEnabled
    }
    return true
  })

  const [_, ...rest] = staticRoutes
  return business.value?.live ? rest : staticRoutes
})
</script>

<style scoped lang="scss">
.main {
  display: grid;
  grid-template-columns: minmax(250px, 20%) 1fr;
  background-color: white;
  height: 100vh;
  // padding-top: v-bind(headerHeight);
  // @apply h-screen bg-white px-5;
}
</style>
