import { ref, computed } from 'vue'
import { VerifyOtp } from '~/src/services/requests/auth/type'

const global = {
  auth: ref(null) as any,
  user: ref(JSON.parse(sessionStorage.getItem('user') as string) as null | VerifyOtp['data']),
  token: ref(sessionStorage.getItem('accessToken')) || null,
}

export const useUser = () => {
  const id = computed(() => global.auth.value?.id ?? '')
  const isLoggedIn = computed(() => global.user.value != null)

  const logOut = (route?: any) => {
    sessionStorage.clear()
    global.user.value = null

    location.href = route || '/auth/login'
  }
  const setUser = (user: VerifyOtp['data']) => {
    global.user.value = user
    sessionStorage.setItem('user', JSON.stringify(user))
    sessionStorage.setItem('accessToken', user.accessToken)
    sessionStorage.setItem('refreshToken', user.refreshToken)
  }

  return { id, isLoggedIn, setUser, ...global, logOut }
}
